import React, { lazy, Suspense } from "react";
import {
	Route,
	withRouter,
	Switch,
	Redirect,
	useParams
} from "react-router-dom";
import { 
	fetchRoute, 
	// _LOGIN_ROUTE2
} from "../../config/page-route.jsx";
import { PageSettings } from "../../config/page-settings.js";
import { connect } from "react-redux";
import Footer from "components/template_1/Footer.js";
import Header from "components/template_1/Header.js";
import reactManifest from "react-manifest";
import manifest from "../../manifest.json";
import LoadingPlaceholder from "components/template_1/LoadingPlaceholder.js";
import { find, includes } from "lodash";
import DepositWithdrawalModal from "components/template_1/DepositWithdrawalModal.js";
import DownlineModal from "components/template_1/DownlineModal.js";
import ShareReferralModal from "components/template_1/ShareReferralModal.js";
import LoginModal from "components/template_1/LoginModal.js";
import {
	WebUrl,
	SessionKey,
	Language
} from "../../util/Constant";
import { isValidLocale, hasValidLocale, extractLocaleFromPathname } from "../../util/Util.js";
import ApiEngine from "util/ApiEngine.js";
import ResetPasswordModal from "components/template_1/ResetPasswordModal.js";
import NotFound from "pages/template_1/NotFound.js";
import Login from "pages/template_1/Login.js";
import ReportOverviewModal from "../../pages/template_1/report/ReportsOverview.js";
import RegisterModal from "components/template_1/RegisterModal.js";
import DailyCheckInModal from "components/template_1/DailyCheckInModal.js";
import ReferralBonusModal from "components/template_1/ReferralBonusModal";
import MyProfileModal from "components/template_1/MyProfileModal";


class Content extends React.Component {
	constructor(props) {
		super(props);
		this._TIMEOUT_TIME = 60 * 60 * 1000;
		this.state = {
			template: props.template ?? "template_1",
			routes: null,
			currroutes: null,
			loginRoute: null,
			currentPage: this.props.history.location.pathname,
			bottomNavIndex: 0,
		};
	}

	ComponentRoute(routeComponent) {
		return routeComponent;
	}

	PrivateRoute(index, routeData) {
		const isLoggedIn = this.props.authState.heartBeat;
		const sessionLocale = localStorage.getItem(SessionKey._LANGUAGE)
		const shouldRedirect = routeData.authRequired && !isLoggedIn;

		// // DEBUG
		// const redirectPath = '/en';
		// const currentReference = routeData.reference;
		// const currentPath = this.props.history.location.pathname;
		// {console.log('currentPath', currentPath)}
		// // Force hard redirect for debugging
		// if (!hasValidLocale(currentPath)) {
		// 	window.location.href = `/${sessionLocale}${currentPath}`;
		// }
		// return (
		// 	<Redirect 
		// 		to={{
		// 			pathname: redirectPath,
		// 			state: { reference: currentReference }
		// 		}}
		// 	/>
		// );

		// CHRIS_LOCALE: Check if locale is valid, if not then redirect to valid locale
		// Get locale from browser url
		const { pathname, state } = this.props.history.location;
		// Check if url contains valid locale

		// console.log('this.props.location', this.props.location)
		if (hasValidLocale(pathname)) {
			// Update locale of session to this new locale
			// console.log('this.extractLocaleFromPathname(pathname)', this.extractLocaleFromPathname(pathname))
			localStorage.setItem(SessionKey._LANGUAGE, extractLocaleFromPathname(pathname));
		} else {
			// CHRIS_LOCALE: Remove locale from url first
			const currentReference = state?.reference; // state.reference is used at GameList.js and PromotionInfo.js
			const currentLocale = WebUrl.getCurrentLocale();
			// console.log('content currentLocale', currentLocale)
			const regex = new RegExp(`^/${currentLocale}(/|$)`);
			const locationPathname = pathname.replace(regex, '/');

			const redirectPath = WebUrl.getLocaleUrl(locationPathname);
			// // Get current url
			// console.log('currentURL', this.props.history.location.pathname)
			// console.log('pathname', pathname)
			// console.log('locationPathname', locationPathname)
			// console.log('currentReference', currentReference)
			// console.log('redirectPath', redirectPath)
			// console.log('shouldRedirect', shouldRedirect)
			// console.log('REDIRECT')

			// this.props.history.replace({
			// 	pathname: redirectPath,
			// 	state: { reference: currentReference }
			// });

			// CHRIS TODO: Problem with passing state reference, because of redirect - need to find a way without redirect?
			// this.props.history.replace doesn't change the url underneath, so it causes problems with API calls
			// this.props.history.push changes the URL so state doesn't get passed and GameList doesn't work
			// Redirect/push might affect other things as well

			return (
				// <Redirect 
				// 	to={{
				// 		pathname: redirectPath,
				// 		state: { reference: currentReference }
				// 	}}
				// />
				<Route
					key={index}
					path={pathname}
					exact={true}
					render={(props) => {
						return <Redirect to={{
							pathname: redirectPath,
							state: { from: props.location, reference: currentReference }
						}} />
					}}
				/>
			);
		}

		return (
			<Route
				key={index}
				path={shouldRedirect ? this.state.loginRoute.path: routeData.path}
				exact={shouldRedirect ? this.state.loginRoute.exact : routeData.exact}
				render={(props) => {
					// <React.Suspense fallback={this.renderFallback()}>
					// 	{ shouldRedirect
					// 			? this.state.loginRoute.component
					// 			: this.ComponentRoute(routeData.component) }
					// </React.Suspense>

					if (shouldRedirect) {
						return <Redirect to={{
							pathname: this.state.loginRoute.path,
							state: { from: props.location }
						}} />	
					} else {
						return <React.Suspense fallback={this.renderFallback()}>
							{this.ComponentRoute(routeData.component)}
						</React.Suspense>
					}
				}}
				// component={
				//   routeData.authRequired && !this.props.authState.heartBeat
				//     ? this.state.loginRoute.component
				//     : routeData.component
				// }
				// exact={routeData.exact}
				// path={routeData.path}
				// component={routeData.component}
			/>
		);
	}

	renderFallback() {
		return this.state.template === "template_1" ? <LoadingPlaceholder /> : null;
	}

	componentWillUpdate() {
		if (this.currentPage !== this.props.history.location.pathname) {
			this.currentPage = this.props.history.location.pathname;
			this.resetTimeout();
		}
	}

	async loadData() {
		try {
			const loginRouteVar = await fetchRoute("template_1", "login");
			const routesVar = await fetchRoute("template_1", "all");

			this.setState({
				routes: routesVar,
				loginRoute: loginRouteVar,
				currroutes: null,
			});
			// Now you can work with the data
		} catch (error) {
			// Handle errors
		}
	}

	componentDidMount() {
		const { dispatch } = this.props;

		this.loadData();

		/*if (this.state.template == "template_1") {
			if (this.props.history.location.pathname !== "/") {
				this.startTimeout = setTimeout(() => {
					window.location.reload();
				}, this._TIMEOUT_TIME);
			}
		}*/
		//KT TODO CHECK THIS CODE FEASIBILITY
	}

	componentWillMount() {
	}

	resetTimeout = () => {
		clearTimeout(this.startTimeout);

		if (this.state.template == "template_1") {
			if (
				this.currentPage !== "/" &&
				this.props.history.location.pathname !== "/"
			) {
				this.startTimeout = setTimeout(() => {
					window.location.reload();
				}, this._TIMEOUT_TIME);
			}
		}
	};

	render() {
		// if (this.state.routes == null) return false;
		// this.state.currroutes = this.state.routes.filter((route) => {
		// 	return route.path == this.props.history.location.pathname;
		// });

		if (this.state.routes == null) return false;

		// Optimization - Filter through routes to eliminate non-matching routes
		this.state.currroutes = this.state.routes.filter((route) => {
			let pathname = route.path;
			let currRoutePath = this.props.history.location.pathname;

			 // Remove leading and trailing slashes for easier comparison
			 const normalizedPathname = pathname.replace(/^\/|\/$/g, '');
			 const normalizedCurrRoutePath = currRoutePath.replace(/^\/|\/$/g, '');
		 
			 // Split paths into segments
			 const routeSegments = normalizedPathname.split('/');
			 const pathSegments = normalizedCurrRoutePath.split('/');
		 
			 // Check for exact matches for non-parameterized routes
			 if (route.exact && normalizedPathname === normalizedCurrRoutePath) {
				 return true;
			 }
		 
			 // Check for locale parameterized routes
			 if (pathname.includes(':locale')) {
				 const localeSegment = pathSegments[0]; // Assuming the locale is the first segment
				 if (isValidLocale(localeSegment)) {
					 // Rebuild the path without the locale for further matching
					 const rebuiltPath = pathSegments.slice(1).join('/');
					 const rebuiltRoutePath = routeSegments.slice(1).join('/');
					 if (rebuiltPath === rebuiltRoutePath || pathname.endsWith(':slug')) {
						 return true;
					 }
				 }
			 }
		 
			 // Handling other parameterized routes that might contain slugs
			 if (!route.exact && pathname.includes(':slug')) {
				 return true; // Placeholder logic; refine this based on actual route structure
			 }

			// // Exact match for non-parameterized routes
			// if (route.exact && pathname === currRoutePath) {
			// 	return true;
			// }

			// if(pathname.includes(':locale')) return true

			// if (!route.exact && pathname.includes(':slug')) {
			// 	// const paramRegex = new RegExp(`/^\/${currRoutePath}\/[^/]+$`); // Adjust this regex as needed for your parameterized routes
			// 	// if (paramRegex.test(currRoutePath)){
			// 	// 	console.log('yes')
			// 	// 	return true;// Match parameterized routes

			// 	// }
			// 	return true
			// }

			return false;
		})

		return (
			<PageSettings.Consumer>
				{({
					pageContentFullWidth,
					pageContentClass,
					pageContentInverseMode,
				}) => (
					<div className="page-container">
						<div
							className={
								"content " +
								(pageContentFullWidth ? "content-full-width " : "") +
								(pageContentInverseMode ? "content-inverse-mode " : "") +
								pageContentClass
							}
						>
							<Header />

							{/* BrowserRouter located in index.js */}
							<Switch>
								{/* <Route exact path="/" component={Login} /> */}

								{this.state.currroutes.map((route, index) => {
									return this.PrivateRoute(index, route);
								})}

								{/* Catch all undefined routes */}
								<Route component={NotFound} />
							</Switch>
							<DailyCheckInModal/>
							<DepositWithdrawalModal />

							<DownlineModal />
							<ReportOverviewModal />
							<ShareReferralModal />

							<LoginModal />
							<RegisterModal/>
							<ResetPasswordModal />
							<ReferralBonusModal />
							<MyProfileModal popout={false} />
							{/* {
								includes(this.props.history.location.pathname, 'livechat') ? "" : <Footer />
							} */}
							<Footer />

						</div>
					</div>
				)}
			</PageSettings.Consumer>
		);
	}
}

const mapStateToProps = (state) => ({
	...state,
});

export default connect(mapStateToProps, null)(withRouter(Content));
