import { themeIcons } from "config/theme";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VIcon from "components/custom/Icon";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import { showResponseMessage, bindMember, setMyProfileModal } from "../../redux/AppAction.js";
import { map, find } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import { createFormBody, stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util.js";
import moment from "moment";
import DatePicker from "react-bootstrap-daterangepicker";
import PinInput from "react-pin-input";
import Skeleton from "react-loading-skeleton";

const VerifyPhoneNumberModal = ({show, phoneNumber, phoneNumType, onClose, onCallback, ...props}) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();

  const memberData = useSelector((state) => state.appState.member);
  var { heartBeat } = useSelector((state) => state.authState);

  const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [showVerifyOtpError, setShowVerifyOtpError] = useState(false);
  const [sentCode, setSentCode] = useState(false);

  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [TACcountdownPeriod, setTACCountdownPeriod] = useState(0);
  const [TACtimer, setTACtimer] = useState(setTimeout(() => { }, 60000));
  const [TACtimedOut, setTACtimedOut] = useState(true);
  const [attemp, setAttemp] = useState(1);
  const elePin = useRef(null);
  const [pinInputDisabled, setPinInputDisabled] = useState(false);
  const [tempPinNumber, setTempPinNumber] = useState();
  const [pinLoading, setPinLoading] = useState(false);

  // const [memberEwalletAccounts, setMemberEwalletAccounts] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedPhoneNumType, setSelectedPhoneNumType]=useState("");

  const _TAC_VALID_TIMER = 60000;
  const _SECONDS_IN_MS = 1000;

  let countdownPeriodTimeOutId, apiTimeOutId;

  useEffect(() => {
    if (show) {
        setSelectedPhoneNumber(phoneNumber);
        setSelectedPhoneNumType(phoneNumType);

        // Reset send OTP modal
        setSentCode(false);
        setTACtimedOut(true);
        setCountdownPeriod(0);
    }
  }, [show, phoneNumber, phoneNumType]);

  // OTP functions
  useEffect(() => {
    if (countdownPeriod > 0) {
      countdownPeriodTimeOutId=setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    }
  }, [countdownPeriod]);

  useEffect(() => {
    if (TACcountdownPeriod > 0) {
      setTACtimer(
        setTimeout(() => {
          let tempPeriod = TACcountdownPeriod - 1;
          setTACCountdownPeriod(tempPeriod);
        }, _SECONDS_IN_MS)
      );
    }
  }, [TACcountdownPeriod]);

  const sendOtp = async () => {
    const OTP_VIA_SMS = 1;
    const channelType = OTP_VIA_SMS;

    let params = {
      phoneNumber: selectedPhoneNumber,
      languageCode: "en",
      countdown: countdownPeriod,
      TACExist: !TACtimedOut,
      type: OTP_VIA_SMS,
      usage: "verifyPhoneNumber",
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_GENERATE_OTP,
      createFormBody(params)
    );

     if (responseJson[ApiKey._API_SUCCESS_KEY]
     ) {
      setSentCode(true);
      setTACtimedOut(false);
      setCountdownPeriod(0);
      setCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
      setAttemp(attemp + 1);
      apiTimeOutId=setTimeout(() => {
        setTACtimedOut(true);
      }, _TAC_VALID_TIMER);
    }else{
      setShowVerifyOtpModal(false);
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }

  const stopTimeout = () => {
    clearTimeout(countdownPeriodTimeOutId);
    clearTimeout(apiTimeOutId);
  };

  const handleVerifyOtp = async (data, e) => {
    // Reset send OTP modal
    setSentCode(false);
    setTACtimedOut(true);
    setCountdownPeriod(0);

    // Open modal
    setShowVerifyOtpModal(true);
  }

  async function onKeyPress(key, index) {
    //setAllowPinClick(false);
    // 1st: have to check currentPinRef
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin
    // let currentIndex = 0;
    let pinStr = tempPinNumber;
    let keyLength = key.split("");
    if (key !== "{bksp}" && key !== "&nbsp;") {
      pinStr = key;
      setTempPinNumber(pinStr);
      if (keyLength.length !== 6) {
      } else {
        setTempPinNumber("");
        setPinInputDisabled(true);
        setPinLoading(true);

        await verifyOTP(pinStr);

        setPinLoading(false);
        setPinInputDisabled(false);
      }
    } else {
      elePin.current.clear();
    }
  }

  const verifyOTP = async (pinstr) => {
    await ApiEngine.post(
      ApiUrl._API_VERIFY_MULTIPLE_PHONE_NUMBER,
      createMultiPartFormBody({
        verificationCode: pinstr,
        phoneNum: selectedPhoneNumber,
        phoneNumType: selectedPhoneNumType
      })
    ).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        if(onCallback) {
            onCallback(response);
        }

        // setShowVerifyOtpModal(false);
        // getMemberEwalletAccounts();

        // if(phoneNumType=="member"){
        //   memberData.isPhoneVerify=true;
        // }
      }

      _dispatch(
        showResponseMessage(
          response[ApiKey._API_SUCCESS_KEY],
          response[ApiKey._API_MESSAGE_KEY]
        )
      );

      // Clear pin inputs
      elePin.current.clear();
    });
  };

  // const getMemberEwalletAccounts = async () => {
  //   let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_PHONE_NUMBERS);
  //   if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //     setMemberEwalletAccounts(responseJson[ApiKey._API_DATA_KEY]);
  //   }
  // }

  return (
    <>
      {/* Modal for verifying phone number using otp */}
      <Modal
        className="modal-custom-fullscreen"
        contentClassName={"t3-custom-modal t3-custom-modal-with-back-btn"}
        fade={false}
        isOpen={show}
        toggle={() => {
          stopTimeout();
          show(false)
        }}
      >
        <ModalHeader>
          <div className="first">
            {/* <div
              onClick={() => {
                // setManageProfileShow(false);
                _dispatch(setMyProfileModal(false))

              }}
            >
              <VIcon
                icon={themeIcons.angleLeft}
                className="vicon theme-icon-size-20"
              />
            </div> */}
            <div>{t("VERIFY_PHONE_NUMBER")}</div>
          </div>
          <div className="second">
            <div 
                className="t3-close-custom-modal" 
                onClick={() => {
                    stopTimeout();
                    if (onClose) {
                      onClose();
                    }
                }}
            >
              <VIcon icon={themeIcons.close} className="vicon" />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="t3-custom-modal-container">
            <div className="modal-body">
              <div>
                {sentCode ? (
                  <div className="d-flex flex-column align-items-center justify-content-center user-available mb-2 mt-2 p-20">
                    <h3 className="t3-pin-title mb-0">
                      {t("VERIFY_YOUR_NUMBER")}
                    </h3>
                    <div className="t3-pin-title2 mb-3">
                      {t("ENTER_THE_CODE_WE_SENT_TO")}{" "}
                      {selectedPhoneNumber}.
                    </div>
                    <style>
                      {`
                        input.pincode-input-text {
                          background: #fff !important;
                          border-radius: 4px !important;
                        }
                      `}
                    </style>
                    <PinInput
                      length={6}
                      // initialValue="------"
                      ref={elePin}
                      // secret
                      type="numeric"
                      disabled={pinInputDisabled}
                      inputMode="numeric"
                      inputStyle={{ border: "0", margin: "0px 5px" }}
                      regexCriteria={/^[ 0-9_@./#&+-]*$/}
                      onComplete={(value, index) => {
                        onKeyPress(value, index);
                      }}
                    />
                    {sentCode && countdownPeriod != 0 && (
                      <p className="tac-countdown mt-2 font12">
                        {t("TAC_SENT")} {countdownPeriod}s
                      </p>
                    )}
                    {/* <div className="mb-2">{t("OTP_SENT_TO")} {memberData.phoneNumber}</div>
                    <input 
                      className="t3-custom-input-text-profile"
                      type="text"
                      placeholder={t("ENTER_OTP")}
                      value={otpValue}
                      onChange={(e) => setOtpValue(e.target.value)}
                      style={{ height: "48px", width: "300px !important", textAlign: "center" }}
                    />
                    {showVerifyOtpError && (
                      <div className="mt-2">
                        <span className="text-danger">{t("INVALID_OTP_ENTERED")}</span>
                      </div>
                    )} */}
                    {/* <button 
                      className="t3-custom-light-btn mt-3"
                      style={{ width: "200px" }}
                      onClick={() => {
                        // setShowVerifyOtpModal(false);
                        // setShowVerifyOtpError(true);
                      }}
                    >
                      {t("VERIFY")}
                    </button> */}
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center user-available mb-4 mt-4">
                    <div>OTP will be sent to {selectedPhoneNumber}</div>
                    <button 
                      className="ml-3 t3-custom-light-btn"
                      onClick={() => {
                        sendOtp();
                      }}
                    >
                      {t("SEND_OTP")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default VerifyPhoneNumberModal;
