import React, { useCallback, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Input from "../../../components/custom/Input";
import { useForm } from "react-hook-form";
import { createMultiPartFormBody } from "../../../util/Util";
import { map, debounce, find, filter, upperCase, isEmpty } from "lodash";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Keyboard from "react-simple-keyboard";
import PinInput from "react-pin-input";
import "react-swipeable-list/dist/styles.css";
import { useSelector, useDispatch } from "react-redux";
import {
	stringIsNullOrEmpty,
	numberWithCurrencyFormat,
	createFormBody,
} from "../../../util/Util";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	TransactionType,
	BankingChannel,
	PanelType,
	AlertTypes,
} from "../../../util/Constant";
import ApiEngine from "../../../util/ApiEngine";
import {
	bindMember,
	setBusy,
	setBusyLoading,
	setCompleteInfoModal,
	setIdle,
	showMessage,
	showResponseMessage,
} from "../../../redux/AppAction";
import "react-simple-keyboard/build/css/index.css";
import StyledSelect from "../../../components/custom/StyledSelect";
import moment from "moment";
import { heartBeat } from "../../../redux/AuthAction";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import Accordion from "react-bootstrap/Accordion";
import LoadingOverlay from "react-loading-overlay";
import classNames from "classnames";
import { Spinner } from "react-bootstrap";
import CompleteInfoModal from "components/template_1/CompleteInfoModal";

const WithdrawByBankTransfer = ({onRenderComplete}) => {
	var { t, i18n } = useTranslation();
	var _history = useHistory();
	let _location = useLocation();
	var _userData = useSelector((state) => state.authState.userData);
	var [action, setAction] = useState([]);
	let _dispatch = useDispatch();
	const [addSuccessModelShow, setAddSuccessModelShow] = useState(false);
	const userFullName = useSelector(
		(state) => state.authState.userData.userFullName
	);
	const [selected, setSelected] = useState(false);
	const [selectedBank, setSelectedBank] = useState([]);
	const [amount, setAmount] = useState(0);
	const [value, setValue] = useState("");
	const [accountNumber, setAccountNumber] = useState("");
	const [accountHolderName, setAccountHolderName] = useState("");
	const [headerMsg, setHeaderMsg] = useState("");
	const [hasPendingTxn, setHasPendingTxn] = useState(false);
	const [countdownStr, setCountdownStr] = useState("");
	const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
	const [transType, setTransType] = useState("");
	const [countdownTime, setCountdownTime] = useState();
	const [isInsufficientAmount, setIsInsufficientAmount] = useState(false);
	const companyCurrency = useSelector((state) => state.appState.companyCurrency);

	// ROSE START HERE
	const {
		handleSubmit,
		register,
		control,
		watch,
		getValues,
		errors,
		clearErrors,
		reset,
		trigger,
	} = useForm();

	const [bankOption, setBankOption] = useState([]);
	const [memberBankAccounts, setMemberBankAccounts] = useState([]);
	const [transcactionNumber, setTranscactionNumber] = useState();
	const [isWithdrawalSuccess, setIsWithdrawalSuccess] = useState(false);
	const [withdrawalAmount, setWithdrawalAmount] = useState(0);
	const [withdrawalDate, setWithdrawalDate] = useState();
	// const [member, setMember] = useState();
	const member = useSelector((state) => state.appState.member);

	const [showPinEntry, setShowPinEntry] = useState(false);
	const [showVerifyPhone, setShowVerifyPhone] = useState(false);
	const [changePinShow, setChangePinShow] = useState(false);
	const _COUNTDOWN_PERIOD = 120;
	const _SECONDS_IN_MS = 1000;
	const _ALERT_TIMEOUT = 3000;
	const [countdownPeriod, setCountdownPeriod] = useState(0);
	const mainForm = useRef(null);

	const [success, setSuccess] = useState(false);
	const [successMsg, setSuccessMsg] = useState("");

	const [pinEntered, setPinEntered] = useState(false);
	
	const [pinNumberFinal, setPinNumberFinal] = useState("");
	const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
	const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);

	const [initDone, setInitDone] = useState(false);
	const [hasRolloverBal, setHasRolloverBal] = useState(false);
	const [memberEmail,setMemberEmail] = useState()

	const [memberPhone,setMemberPhone] = useState()


	const [tempPhoneNum, setTempPhoneNum] = useState("");
	const verifyState = {
		button: "1",
	};



	const [transactionData, setTransactionData] = useState([]);
	const [showFullnamePopup, setShowFullnamePopup] = useState(false);

	


	const [showModal, setShowModal] = useState(false);
	const [displayDataIndex, setDisplayDataIndex] = useState(-1);
	const [min, setMin] = useState(0);
	const customerServiceUrl = "/livechat";
	const [totalWithdrawAmountToday, setTotalWithdrawAmountToday] = useState(0);
	const [totalWithdrawCountToday, setTotalWithdrawCountToday] = useState(0);
	const [isWithdrawalLimitError, setIsWithdrawalLimitError] = useState(0);
	const [remainingWithdrawalLimit, setRemainingWithdrawalLimit] = useState(0);
	const [memberWithdrawalAmountLimit, setMemberWithdrawalAmountLimit] = useState(0);
	const specificElementRef = useRef(null); 

    const [isWithdrawLoading, setIsWithdrawLoading] = useState(false)

	useEffect(() => {
		init();

	}, []);

	useEffect(() => {
		if (member) {
			if (!stringIsNullOrEmpty(member.userFullName)) {
				setAccountHolderName(member.userFullName);
			}
		} else {
			if (!stringIsNullOrEmpty(userFullName)) {
				setAccountHolderName(userFullName);
			}
		}
	}, [member, userFullName]);

	useEffect(() => {
		const checkElementRendered = () => {
		  if (specificElementRef.current) {
			onRenderComplete();
		  }
		};
	
		checkElementRendered();
	  }, [onRenderComplete]);

	useEffect(() => {
		if (countdownPeriod > 0) {
			clearTimeout(countdownTime);
			setCountdownTime(
				setTimeout(() => {
					let tempPeriod = countdownPeriod - 1;
					setCountdownPeriod(tempPeriod);
					let tempX = moment.duration(tempPeriod);
					let tempMM = Math.floor(tempX["_milliseconds"] / 60);
					let tempSS = tempX["_milliseconds"] - tempMM * 60;
					setTimeDiffSeconds(tempX["_milliseconds"]);
					let tempStr =
						(tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
						" " +
						t("MINUTES") +
						" " +
						(tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
						" " +
						t("SECOND");
					setCountdownStr(tempStr);
				}, _SECONDS_IN_MS)
			);
		}
	}, [countdownPeriod]);

	useEffect(() => {
		getMemberBankAccount();
		getBankList();
	}, []);
	useEffect(()=>{
		console.log(member)
	},[member])

	useEffect(() => {
		if (bankOption && !stringIsNullOrEmpty(bankOption)) {
			if (!stringIsNullOrEmpty(selectedBank)) {
				const bankInfo = bankOption.filter(
					(x) => x.value == value
				)[0];
				console.log(bankOption)
				console.log(value)

				if(bankInfo){
					setMin(bankInfo["min"]);

				}
				// console.log(bankInfo)
			}
		}
	}, [bankOption, value]);

	useEffect(() => {
		if(member){
			// console.log(member.membership.maxWithdrawalAmount);
			// console.log(member.membership.maxWithdrawalCount);
			// console.log(totalWithdrawAmountToday);
			// console.log(totalWithdrawCountToday);
			setMemberWithdrawalAmountLimit(member.membership.maxWithdrawalAmount)
			////error 1 - Withdrawal limit amount is maxed out for today
			////error 2 - Withdrawal limit count is maxed out for today
			////error 3 - if value of input amount is greater than the withdraw amount limit remaining
			if (totalWithdrawAmountToday >= member.membership.maxWithdrawalAmount && member.membership.maxWithdrawalAmount > 0){
				setIsWithdrawalLimitError(1);
			} else if (member.membership.maxWithdrawalCount >0 && totalWithdrawCountToday >= member.membership.maxWithdrawalCount && member.membership.maxWithdrawalCount != -1) {
				setIsWithdrawalLimitError(2);
			} else {
				const withdrawAmountLimitRemainingToday = member.membership.maxWithdrawalAmount - totalWithdrawAmountToday;
				// console.log(withdrawAmountLimitRemainingToday)
				// console.log(amount)
				if (withdrawAmountLimitRemainingToday < amount && member.membership.maxWithdrawalAmount > 0){
					setRemainingWithdrawalLimit(withdrawAmountLimitRemainingToday);
					setIsWithdrawalLimitError(3);
					console.log("the amount is greater than your daily limit to withdraw, here's your remaining total amount to withdraw for today - " + withdrawAmountLimitRemainingToday);
				} else {
					setIsWithdrawalLimitError(0);
				}
			}
		
		
		}
		
	}, [totalWithdrawCountToday,totalWithdrawAmountToday,member, amount]);

	async function init() {
		try {
			if (_userData["walletBalance"] == 0) {
				setIsInsufficientAmount(true);
			}

			// CHRIS 20241206 - Removing check for pending transaction because WithdrawalMethod already has it
			// var oktocont = true;
			// setHeaderMsg("");
			// var pendingTransactionJson = await ApiEngine.get(
			// 	ApiUrl._API_GET_PENDING_TRANSACTION
			// );
			// if (pendingTransactionJson[ApiKey._API_SUCCESS_KEY]) {
			// 	if (pendingTransactionJson[ApiKey._API_DATA_KEY] != null) {
			// 		clearTimeout(countdownTime);
			// 		let tempS = parseInt(
			// 			pendingTransactionJson[ApiKey._API_DATA_KEY]["timeSpan"]
			// 		);
			// 		setTransType(
			// 			pendingTransactionJson[ApiKey._API_DATA_KEY]["transType"]
			// 		);
			// 		setTimeDiffSeconds(tempS);
			// 		setCountdownPeriod(tempS < 0 ? 0 : tempS);
			// 		setHasPendingTxn(true);
			// 		oktocont = false;
			// 		setHeaderMsg(t("NEW_TXN_PROHIBITED"));
			// 	}
			// } else {
			// 	_dispatch(
			// 		showResponseMessage(
			// 			false,
			// 			pendingTransactionJson[ApiKey._API_MESSAGE_KEY]
			// 		)
			// 	);
			// }

			// //KT: IF PENDING TXN FOUND, WHY CONTINUE AND STILL GET DATA WE DONT NEED?
			// if (!oktocont) {
			// 	return false;
			// }

			// let tempMemberData = {};
			getLatestRolloverRecord(); //KT: WHY DO WE NEED TO CALL TWICE?
			// var rolloverJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_ROLLOVER); //KT: AND CALL THIS? 
			getMemberDetails();
			// if (rolloverJson[ApiKey._API_SUCCESS_KEY]) {
				// var memberJson = await ApiEngine.get(
				// 	ApiUrl._API_GET_MEMBER_DETAILS
				// );

				// if (memberJson[ApiKey._API_SUCCESS_KEY]) {
				// 	console.log('info', memberJson[ApiKey._API_DATA_KEY])
				// 	setMember(memberJson[ApiKey._API_DATA_KEY]);
				// 	tempMemberData = memberJson[ApiKey._API_DATA_KEY];
				// 	setTempPhoneNum(
				// 		tempMemberData["phoneNumber"].replace(
				// 			tempMemberData["phoneNumber"].substr(
				// 				3,
				// 				tempMemberData["phoneNumber"].length - 7
				// 			),
				// 			tempMemberData["phoneNumber"]
				// 				.substr(1, tempMemberData["phoneNumber"].length - 4)
				// 				.replace(/./g, "*")
				// 		)
				// 	);
				// } else {
				// 	throw memberJson[ApiKey._API_MESSAGE_KEY];
				// }

				///get total withdrawal
				let tempWithdData = {};
				var transactionTodayJson = await ApiEngine.get(
					ApiUrl._API_GET_TRANSACTION_TODAY_BY_USER
				);

				if (transactionTodayJson[ApiKey._API_SUCCESS_KEY]) {
					
					let transactionsToday = transactionTodayJson[ApiKey._API_DATA_KEY];
					if(transactionsToday){
						const totalAmount = transactionsToday.reduce((accumulator, transactionsToday) => {
							return accumulator + transactionsToday.amount;
						}, 0);
						setTotalWithdrawAmountToday(totalAmount);
						setTotalWithdrawCountToday(transactionsToday.length);
					}
					
				} else {
					throw transactionTodayJson[ApiKey._API_MESSAGE_KEY];
				}


				


				// getLatestRolloverRecord(); //KT: WHY DO WE NEED TO CALL TWICE?
				let responseJson = await ApiEngine.get(
					ApiUrl._API_GET_USER_BANK + "?type=WITHDRAWAL"
				);
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					let bankList = [];
					responseJson[ApiKey._API_DATA_KEY].map((bankAccount) => {
						bankList.push({
							label: bankAccount.bankName,
							value: bankAccount.id,
							bankName: bankAccount.bankName,
							bankCode: bankAccount.bankCode,
							min: bankAccount.minimumWithdrawal,
							max: bankAccount.maximumWithdrawal,
						});
					});
					setBankOption(bankList);
				} else {
					throw responseJson[ApiKey._API_MESSAGE_KEY];
				}

				getMemberBankAccount();
				_dispatch(heartBeat());
			// } else {
			// 	setHasRolloverBal(true);
			// 	throw rolloverJson[ApiKey._API_MESSAGE_KEY];
			// }
		} catch (err) {
			_dispatch(showResponseMessage(false, err));
		}
		setInitDone(true);
	}

	async function getMemberDetails() {
		await _dispatch(bindMember)

		
		// let tempMemberData = {};

		// var memberJson = await ApiEngine.get(
		// 	ApiUrl._API_GET_MEMBER_DETAILS
		// );

		// if (memberJson[ApiKey._API_SUCCESS_KEY]) {
		// 	console.log('info', memberJson[ApiKey._API_DATA_KEY])
		// 	setMember(memberJson[ApiKey._API_DATA_KEY]);
		// 	tempMemberData = memberJson[ApiKey._API_DATA_KEY];
		// 	setTempPhoneNum(
		// 		tempMemberData["phoneNumber"].replace(
		// 			tempMemberData["phoneNumber"].substr(
		// 				3,
		// 				tempMemberData["phoneNumber"].length - 7
		// 			),
		// 			tempMemberData["phoneNumber"]
		// 				.substr(1, tempMemberData["phoneNumber"].length - 4)
		// 				.replace(/./g, "*")
		// 		)
		// 	);
		// } else {
		// 	throw memberJson[ApiKey._API_MESSAGE_KEY];
		// }
	}

	async function getLatestRolloverRecord() {
		var responseJson = await ApiEngine.get(
			ApiUrl._API_GET_LATEST_ROLLOVER_RECORD
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setTransactionData(responseJson[ApiKey._API_DATA_KEY]);
		}
	}

	async function getMemberBankAccount() {
		try {
			let responseJson = await ApiEngine.get(
				ApiUrl._API_GET_MEMBER_BANK_ACCOUNT_BY_MEMBER
			);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				const data = responseJson[ApiKey._API_DATA_KEY]?.[0];

				if (!stringIsNullOrEmpty(data)) {
					setAccountNumber(data["accountNumber"]);
					// setValue('addAccountNumber')
					setValue(data["bankId"]);

					let bankAccountList = [];
					responseJson[ApiKey._API_DATA_KEY].map((bankAccount) => {
						bankAccountList.push({
							label: bankAccount.bankName + " - " + bankAccount.accountNumber,
							value: bankAccount.id,
							bankId: bankAccount.bankId,
							accountHolderName: bankAccount.accountHolderName,
							accNum: bankAccount.accountNumber,
							bankName: bankAccount.bankName,
							bankCode: bankAccount.bankCode,
							isDefault: bankAccount.isDefault,
						});
					});

					setSelectedBank(bankAccountList?.[0]);
					setMemberBankAccounts(bankAccountList);
				}
			} else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		} catch (err) {
			_dispatch(showResponseMessage(false, err));
		}
	}

	async function submitWithdrawal() {
		// _dispatch(setBusy());
		// console.log(memberBankAccounts)
		_dispatch(setBusyLoading(true))

		setIsWithdrawLoading(true)
		await getMemberDetails();

		if(isEmpty(member.email) || isEmpty(member.phoneNumber))
		{
			_dispatch(setCompleteInfoModal(true))

			setIsWithdrawLoading(false)
					_dispatch(setBusyLoading(false))


			return false
		}

			// return false


		// _dispatch(setBusyLoading(true))

		if (stringIsNullOrEmpty(memberBankAccounts)) {
			let createAccParams = {
				bankId: value,
				accountNumber: accountNumber,
				accountHolderName: accountHolderName,
			};


			let params = {
				userFullName: accountHolderName,
			};

			
			let responseJson = await ApiEngine.post(
				ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
				createFormBody(params)
			);

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
				_dispatch(
					showResponseMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						responseJson[ApiKey._API_MESSAGE_KEY]
					)
				);
				return false
			}

			let createAccResponseJson = await ApiEngine.post(
				ApiUrl._API_CREATE_OR_UPDATE_MEMBER_BANK_ACCOUNT,
				createMultiPartFormBody(createAccParams)
			);

			if (createAccResponseJson[ApiKey._API_SUCCESS_KEY]) {
				let createTransParams = {
					transactionTypeId: TransactionType._WITHDRAWAL,
					amount: amount,
					channel: "",
					panel: PanelType._MEMBERSITE,
					bankAccountNumber: accountNumber,
					bankAccountHolderName: accountHolderName,
					bankName: selectedBank.bankName,
					bankCode: selectedBank.bankCode,
				};

				let createTransResponseJson = await ApiEngine.post(
					ApiUrl._API_CREATE_TRANSACTION,
					createFormBody(createTransParams)
				);

				if (createTransResponseJson[ApiKey._API_SUCCESS_KEY]) {
					setShowPinEntry(false);
					setWithdrawalAmount(amount);
					setIsWithdrawalSuccess(true);
					setWithdrawalDate(moment().format("DD MMM YYYY, hh:mm A"));
					setTranscactionNumber(createTransResponseJson[ApiKey._API_DATA_KEY]);
					_dispatch(heartBeat());
					init();
				} else {
					_dispatch(
						showResponseMessage(
							createTransResponseJson[ApiKey._API_SUCCESS_KEY],
							createTransResponseJson[ApiKey._API_MESSAGE_KEY]
						)
					);
				}
				setPinEntered(false);
				setPinNumberFinal("");
				setEnteredPinNumberFinal("");
				setPinNumberFinalFocused(0);
				setShowPinEntry(false);
			} else {
				_dispatch(
					showResponseMessage(
						createAccResponseJson[ApiKey._API_SUCCESS_KEY],
						createAccResponseJson[ApiKey._API_MESSAGE_KEY]
					)
				);
			}
		} else {

			let createTransParams2 = {
				transactionTypeId: TransactionType._WITHDRAWAL,
				amount: amount,
				channel: "",
				panel: PanelType._MEMBERSITE,
				bankAccountNumber: accountNumber,
				bankAccountHolderName: accountHolderName,
				bankName: selectedBank.bankName,
				bankCode: selectedBank.bankCode,
			};

			let createTransResponseJson = await ApiEngine.post(
				ApiUrl._API_CREATE_TRANSACTION,
				createFormBody(createTransParams2)
			);

			if (createTransResponseJson[ApiKey._API_SUCCESS_KEY]) {
				setShowPinEntry(false);
				setWithdrawalAmount(amount);
				setIsWithdrawalSuccess(true);
				setWithdrawalDate(moment().format("DD MMM YYYY, hh:mm A"));
				setTranscactionNumber(createTransResponseJson[ApiKey._API_DATA_KEY]);
				_dispatch(heartBeat());
				init();
			} else {
				_dispatch(
					showResponseMessage(
						createTransResponseJson[ApiKey._API_SUCCESS_KEY],
						createTransResponseJson[ApiKey._API_MESSAGE_KEY]
					)
				);
			}
			setPinEntered(false);
			setPinNumberFinal("");
			setEnteredPinNumberFinal("");
			setPinNumberFinalFocused(0);
			setShowPinEntry(false);
		}

		getMemberBankAccount();
		// _dispatch(setIdle());
		setIsWithdrawLoading(false)

		_dispatch(setBusyLoading(false))

	}

	async function onSubmit(data, e) {
		submitWithdrawal();
		//setShowPinEntry(true);
	}

	const getBankList = async () => {
		let responseJson = await ApiEngine.get(
			ApiUrl._API_GET_USER_BANK + "?type=WITHDRAWAL"
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let bankList = [];
			responseJson[ApiKey._API_DATA_KEY].map((bankAccount) => {
				bankList.push({
					label: bankAccount.bankName,
					value: bankAccount.id,
					bankName: bankAccount.bankName,
					bankCode: bankAccount.bankCode,
					min: bankAccount.minimumWithdrawal,
					max: bankAccount.maximumWithdrawal,
				});
			});
			setBankOption(bankList);
		} else {
			throw responseJson[ApiKey._API_MESSAGE_KEY];
		}
	};

	async function submitFullName(data) {
		let params = {
			userFullName: data.userFullName,
		};

		let responseJson = await ApiEngine.post(
			ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
			createFormBody(params)
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			_dispatch(
				showResponseMessage(
					responseJson[ApiKey._API_SUCCESS_KEY],
					responseJson[ApiKey._API_MESSAGE_KEY]
				)
			);
			setShowFullnamePopup(false);
		} else {
			_dispatch(
				showResponseMessage(
					responseJson[ApiKey._API_SUCCESS_KEY],
					responseJson[ApiKey._API_MESSAGE_KEY]
				)
			);
		}
		// init();
	}

	const handleDownlineOnClick = async (index, e) => {
		_dispatch(setBusy());
		e.preventDefault();
		if (displayDataIndex === index) {
			setDisplayDataIndex(-1);
		} else {
			setDisplayDataIndex(index);
		}
		_dispatch(setIdle());
	};

	function handleSelectedBank(value) {
		const bank = bankOption.filter((x) => x.value == value)[0];

		setSelectedBank(bank);
		setValue(value);
	}



	return (
		<>
			{/* {hasPendingTxn && (
				<div className="mt-3 mb-3 reminder-yellow">
					<div className="first">
						<div className="first">
							<img
								src={require("../../../assets/img/new_icons/yellow.png")}
								className="img-responsive"
								alt="icon"
							/>
						</div>
						<div>
							<p className="font14 mb-0 f-weight-500">
								{timeDiffSeconds > 0
									? t("PLEASE_WAIT") +
										" " +
										countdownStr +
										" " +
										(transType === "Deposit"
											? t("FOR_DEPOSIT_APPROVAL")
											: t("FOR_WITHDRAWAL_APPROVAL"))
									: headerMsg}
							</p>
						</div>
					</div>
				</div>
			)} */}
			{success && (
				<div className="mt-3 mb-3 reminder-green">
					<div className="first">
						<div className="first">
							<img
								src={require("../../../assets/img/new_icons/green.png")}
								className="img-responsive"
								alt="icon"
							/>
						</div>
						<div>
							<p className="font14 mb-0 f-weight-500">{t(successMsg)}</p>
						</div>
					</div>
				</div>
			)}
			{!hasPendingTxn && !showModal && initDone && isWithdrawalLimitError != 1 && isWithdrawalLimitError != 2 && (
				<>
					<div className="t3-reload-bank-list-box d-block position-relative">
						<div className="reload-bank-details d-flex justify-content-between align-items-center">
							<span className="font14">{t("BALANCE")}</span>
							<span className="font14">
								{numberWithCurrencyFormat(
									_userData["walletBalance"],
									2,
									true
								)}
							</span>
						</div>
						<div className="reload-bank-details d-flex justify-content-between align-items-center">
							<span className="font14">{t("MIN_WITHDRAWAL")}</span>
							<span className="font14">
								{min ?
									numberWithCurrencyFormat(
										min,
										2,
										true
									)
								:
									"-"
								}
							</span>
						</div>
						<div className="border-top mt-2 mb-2"></div>
						<div className="reload-bank-details">
							<span className="yellow-note-title">{t("NOTES")} : </span>
							<p className="font14">{t("WITHDRAWAL_NOTES") + "."}</p>
							<p className="font14">
								{stringIsNullOrEmpty(memberBankAccounts)
									? t("BANK_ACC_WARNING") + "."
									: t("WITHDRAWAL_WARNING") + "."}
							</p>
						</div>
					</div>

					<form onSubmit={handleSubmit(onSubmit)} ref={mainForm}>
						<div className="deposit-withdraw-form-label">{t("BANK_ACCOUNT_INFO")}</div>
						<div className="t3-deposit-box-wrapper">
							<div className="t3-withdraw-form-style-box">
								<div className="t3-input-withdrawal d-flex justify-content-between align-items-center">
									<label className="required col-3 mb-0 p-0">
										{t("BANK_NAME")}
									</label>
									<div id="t3-custom-modal-settings" className="col-9 pr-0">
										<select
											className={
												// !hasRolloverBal &&
												_userData.walletBalance >
													numberWithCurrencyFormat(parseFloat(0), 2, true) &&
												stringIsNullOrEmpty(memberBankAccounts)
													? "t3-withdrawal-option-select"
													: "t3-withdrawal-option-select disabled"
											}
											name="bank"
											ref={register({
												required: "FIELD_REQUIRED",
											})}
											defaultValue={value}
											placeholder={t("PLEASE_SELECT_BANK")}
											onChange={(e) => {
												setValue("bank",e.target.value)
												handleSelectedBank(e.target.value);
												clearErrors("bank");

											}}
											disabled={
												// !hasRolloverBal &&
												_userData.walletBalance >
													numberWithCurrencyFormat(parseFloat(0), 2, true) &&
												stringIsNullOrEmpty(memberBankAccounts)
													? false
													: true
											}
										>
											<option value="" disabled>
												{t("PLEASE_SELECT_BANK")}
											</option>
											{bankOption.map((option, index) => {
												return (
													<option
														className="t3-withdrawal-option-value"
														value={option.value}
														key={index}
													>
														{option.label}
													</option>
												);
											})}
										</select>
										{errors.bank&& (
											<div className="d-flex align-items-center error-msg mt-1">
												<div className="font12 text-lgray3 second text-customred">
													{t(errors.bank.message)}
												</div>
											</div>
										)}
									</div>
								</div>

								<div className="t3-input-withdrawal d-flex justify-content-between align-items-center mt-2">
									<label className="col-3 mb-0 p-0">
										{t("ACCOUNT_HOLDER_NAME")}
									</label>
									<div className="position-relative col-9 pr-0">
										<Input
											id="add-account-holder-name"
											name="addAccountHolderName"
											ref={register({
												required: "FIELD_REQUIRED",
												pattern: {
													value: /^[a-z A-Z]+$/,
													message: "INVALID_NAME",
												},
											})}
											placeholder={t("ENTER_YOUR_ACCOUNT_NAME")}
											className="t3-withdrawal-input-text readonly"
											autoFocus
											defaultValue={accountHolderName}
											style={
												stringIsNullOrEmpty(_userData.userFullName) 
													? null
													: { pointerEvents: "none" }
											}
											onChange={(e) => setAccountHolderName(e.target.value)}

											readOnly={
												stringIsNullOrEmpty(_userData.userFullName) && _userData.walletBalance > 0
													? false
													: true
											}
										/>
										{errors.addAccountHolderName&& (
											<div className="d-flex align-items-center error-msg mt-1">
												<div className="font12 text-lgray3 second text-customred">
													{t(errors.addAccountHolderName.message)}
												</div>
											</div>
										)}
									</div>
								</div>

								{
									_userData.walletBalance <= 0 && (
										<div className="mt-2 mb-2 reminder-red full">
										<div className="first">
											<div className="first">
											<img
												src={require("../../../assets/img/new_icons/red.png")}
												className="img-responsive"
												alt="icon"
											/>
											</div>
											<div>
											<p className="text-left font14 mb-0 f-weight-500">
												<span>{t('ACCOUNT_BALANCE_ZERO')}</span>
												{/* <span dangerouslySetInnerHTML={{ __html: t('FULLNAME_WARNING_MESSAGE', { url: customerServiceUrl }) }} /> */}
											</p>
											</div>
										</div>
									</div>
									)
								}
								{ stringIsNullOrEmpty(_userData.userFullName) && _userData.walletBalance > 0 &&
									<div className="mt-2 mb-2 reminder-red full">
										<div className="first">
											<div className="first">
											<img
												src={require("../../../assets/img/new_icons/red.png")}
												className="img-responsive"
												alt="icon"
											/>
											</div>
											<div>
											<p className="text-left font14 mb-0 f-weight-500">
												<span dangerouslySetInnerHTML={{ __html: t('FULLNAME_WARNING_MESSAGE', { url: customerServiceUrl }) }} />
											</p>
											</div>
										</div>
									</div>
								
								}
								

								<div className="t3-input-withdrawal d-flex justify-content-between align-items-center mt-2">
									<label className="required col-3 mb-0 p-0">
										{t("ACCOUNT_NUMBER")}
									</label>
									<div className="position-relative col-9 pr-0">
										<Input
											id="add-account-number"
											name="addAccountNumber"
											type="number"
											ref={register({
												required: "FIELD_REQUIRED",
											})}
											placeholder={t("ENTER_YOUR_ACCOUNT_NUMBER")}
											className="t3-withdrawal-input-text"
											autoFocus
											// value={accountNumber}
											defaultValue={accountNumber}
											style={
												!hasRolloverBal &&
												_userData.walletBalance >
													numberWithCurrencyFormat(parseFloat(0), 2, true) &&
												stringIsNullOrEmpty(memberBankAccounts)
													? null
													: { pointerEvents: "none" }
											}
											onChange={(e) => setAccountNumber(e.target.value)}
											readOnly={
												stringIsNullOrEmpty(memberBankAccounts) &&
												_userData.walletBalance >
													numberWithCurrencyFormat(parseFloat(0), 2, true)
													? false
													: true
											}
										/>
										{errors.addAccountNumber&& (
											<div className="d-flex align-items-center error-msg mt-1">
												<div className="font12 text-lgray3 second text-customred">
													{t(errors.addAccountNumber.message)}
												</div>
											</div>
										)}
									</div>
								</div>

								<div className="t3-input-withdrawal d-flex justify-content-between align-items-center mt-2">
									<label className="required col-3 mb-0 p-0">
										{t("AMOUNT")}
									</label>
									<div className="t3-withdraw-form-style-box position-relative col-9 pr-0">
										<div className="t3-wthdraw-input-style-box">
											<div className="first">{companyCurrency}</div>
											<div className="second">
												<input
													type="text"
													name="amount"
													ref={register()}
													// value={Number(amount).toFixed(2)}
													value={
														companyCurrency === "PKR"
															? parseInt(amount) // Show without decimals for AUD
															: Number(amount).toFixed(2) // Show with decimals for other currencies
													}
													placeholder={
														t("ENTER_THE_AMOUNT") + " (" + companyCurrency + " " +
														numberWithCurrencyFormat(parseFloat(1), 2, true) +
														")"
													}
													className={
														_userData["walletBalance"] > 0
															? ""
															: "disabled-action"
													}
													onChange={(e) => {
														let amount = e.target.value
															.replace(
																/[a-zA-Z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?(kr|$|£|€)]*/g,
																""
															)
															.trim();
														let value = 0
														if (companyCurrency === "PKR") {
															
															value = Number(parseFloat(amount)).toFixed(0)
														}
														else{
															value = Number(parseFloat(amount) / 100).toFixed(2);
												
														}
														// let value = Number(
														// 	parseFloat(amount) / 100
														// ).toFixed(2);
														if (
															parseFloat(value) >
															parseFloat(_userData.walletBalance)
														) {
															value = _userData.walletBalance;
														}
														setAmount(value);
														e.target.setSelectionRange(
															value.length,
															value.length
														);
													}}
													disabled={
														!hasRolloverBal &&
														_userData.walletBalance >
															numberWithCurrencyFormat(parseFloat(0), 2, true)
															? ""
															: "disabled"
													}
												/>
											</div>
										</div>
										{isWithdrawalLimitError == 3 && (
											<>
												<div class="mt-2 pr-1 text-right">
													{t("WITHDRAW_LIMIT_ERROR", {
														remainingWithdrawalLimit: remainingWithdrawalLimit,
														memberWithdrawalAmountLimit: memberWithdrawalAmountLimit,
													})}
												</div>
											</>
										)}
									</div>
								</div>
								
								
							</div>
						</div>
						{/* {transactionData &&
							transactionData.length > 0 &&
							map(transactionData, (item, index) => {
								return (
									<>
										<Accordion className="rollover-accordion mt-3 mb-3">
											<Accordion.Item
												eventKey={item.id}
												style={{ cursor: "pointer" }}
											>
												<Accordion.Header
													onClick={(e) => {
														handleDownlineOnClick(index, e);
													}}
												>
													<div className="d-flex justify-content-between align-items-start w-100">
														<div className="text-left">
															<span className="mb-0 font-14">
																{item["date"]}
															</span>
															<p className="font12 text-lgray2 mb-0">
																{t("LATEST_TOP_UP_AND_BONUS")}
															</p>
														</div>
														<div>
															{item["amount"] === item["amount"] && (
																<div className="t3-progress-bar-box">
																	<span
																		className="progress-identifier"
																		style={{
																			width:
																				(item["isLatestRecord"] == 1
																					? item["rolloverProgress"]
																					: item["rolloverTarget"]) == 0 &&
																				item["rolloverTarget"] == 0
																					? "0%"
																					: ((item["isLatestRecord"] == 1
																							? item["rolloverProgress"]
																							: item["rolloverTarget"]) /
																							item["rolloverTarget"]) *
																							100 +
																						"%",
																		}}
																	></span>
																</div>
															)}
															<div
																className="d-flex justify-content-center align-items-center font14 f-weight-500"
																style={{ justifyContent: "flex-end" }}
															>
																<div>
																	{numberWithCurrencyFormat(
																		parseFloat(item["amount"]),
																		2,
																		true
																	)}
																</div>
																{item["amount"] === item["amount"] && (
																	<div>
																		{displayDataIndex === index ? (
																			<div className="img1">
																				<VIcon
																					icon={themeIcons.angleDown}
																					className="theme-icon-blue-size-20"
																				/>
																			</div>
																		) : (
																			<div className="img2">
																				<VIcon
																					icon={themeIcons.angleUp}
																					className="theme-icon-blue-size-20"
																				/>
																			</div>
																		)}
																	</div>
																)}
															</div>
														</div>
													</div>
												</Accordion.Header>
												<Accordion.Body>
													<div className="d-flex align-item-center justify-content-between mt-2">
														<div className="font12 mb-1 text-lgray2">
															{t("ROLLOVER")}
														</div>
														<div className="font12">
															{item["isLatestRecord"] == 1
																? item["rolloverProgress"]
																: item["rolloverTarget"]}{" "}
															/ {item["rolloverTarget"]}
														</div>
													</div>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</>
								);
							})} */}

						<div  ref={specificElementRef} className="t3-withdraw-buttons text-center mt-4 mb-2">
							<button 
								type="submit"
								disabled={amount > 0.99 && isWithdrawalLimitError == 0 ? "" : "disabled" || hasRolloverBal || isWithdrawLoading}
								className={`t3-custom-light-btn  
							${amount > 0.99 && isWithdrawalLimitError == 0 ? "" : "disabled"}`}
							>
									{isWithdrawLoading ? (
									<Spinner
										className="dynamic-table-spinner"
										animation="border"
										role="status"
										size="sm"
										style={{
										verticalAlign: "middle",
										width: "1.5rem",
										height: "1.5rem",
										marginLeft: 3,
										}}
									></Spinner>
									) : (
									t("WITHDRAW")
								)}
							</button>
						</div>
					</form>
				</>
			)}
			{/* ////error 1 - Withdrawal limit amount is maxed out for today */}
			{!hasPendingTxn && !showModal && initDone && isWithdrawalLimitError == 1 && (
				<>
					<div ref={specificElementRef} class="mt-2 pr-1 text-danger text-center">{t("WITHDRAWAL_LIMIT_AMOUNT_MAX_ERROR")}</div>
				</>
			)}
			{/* ////error 2 - Withdrawal limit count is maxed out for today */}
			{ !hasPendingTxn && !showModal && initDone && isWithdrawalLimitError == 2 && (
				<>
					<div ref={specificElementRef} class="mt-2 pr-1 text-danger text-center">{t("WITHDRAWAL_LIMIT_COUNT_MAX_ERROR")}</div>
				</>
			)}
			

			{/* WITHDRAW TRANSACTION SUCCESS START */}
			<Modal
				fade={false}
				id="t3-custom-modal-withdraw"
				isOpen={isWithdrawalSuccess}
				toggle={() => {
					setIsWithdrawalSuccess(false);
				}}
				centered
			>
				<ModalBody>
					<div className="t3-custom-modal-withdraw-container t3-modal-bg-dark">
						<div className="t3-withdraw-modal-body t3-pin-modal-body d-flex align-items-center">
							<div
								className="t3-withdraw-success justify-content-center"
								id="Create-account"
							>
								<div className="text-center">
									<div className="">
										<img
											src={require("../../../assets/img/new_icons/checked.png")}
											className="img-responsive"
											alt="icon"
										/>
									</div>
									<h2 className="mt-3 text-white">
										{t("WITHDRAWAL_REQUEST_SUBMMITED")}
									</h2>
									<p className="font16 text-lgray2">
										{t("REQUEST_RECEIVED")}
									</p>
								</div>
								<div className="width-100-p mt-5">
									<a
										className="t3-custom-light-btn w-100 mt-4 d-flex align-items-center justify-content-center"
										onClick={() => {
											setIsWithdrawalSuccess(false);
											setSelected(false);
										}}
										style={{ cursor: "pointer" }}
									>
										{t("GOT_IT")}
									</a>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
			{/* WITHDRAW TRANSACTION SUCCESS END */}
			{/* SUCESS MODAL START */}
			<Modal
				fade={false}
				id="t3-custom-modal-withdraw"
				isOpen={addSuccessModelShow}
				toggle={() => {
					setAddSuccessModelShow(false);
				}}
				centered
			>
				<ModalBody>
					<div className="t3-custom-modal-withdraw-container t3-modal-bg-dark">
						<div className="t3-withdraw-modal-body t3-pin-modal-body d-flex align-items-center">
							<div
								className="t3-withdraw-success justify-content-center w-100"
								id="Create-account"
							>
								<div className="text-center">
									<div className="">
										<img
											src={require("../../../assets/img/new_icons/checked.png")}
											className="img-responsive"
											alt="icon"
										/>
									</div>
									<h2 className="mt-3 text-white">{action} successful!</h2>
									<p className="font16 text-lgray2">{action} is completed!</p>
								</div>
								<div className="width-100-p mt-5">
									<a
										className="t3-custom-light-btn w-100 mt-4 d-flex align-items-center justify-content-center"
										onClick={() => {
											setAddSuccessModelShow(false);
											setSelected(false);
										}}
										style={{ cursor: "pointer" }}
									>
										{t("GOT_IT")}
									</a>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
			{/* SUCCESS MODAL END */}
			<CompleteInfoModal/>

		</>
	);
};

export default WithdrawByBankTransfer;
